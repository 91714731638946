<template>
	<div class="homeSet">
		<div class="homeSet-box">
			<div class="box-item">
				<div class="item-title">显示</div>
				<el-switch v-model="open_goods" active-color="#fa8919"></el-switch>
			</div>
		</div>
		<div class="homeSet-box" v-if="open_goods == true">
			<div class="box-item">
				<div class="item-title">列表样式</div>
				<el-radio-group v-model="goods_view_kind">
					<el-radio :label="1">横版</el-radio>
					<el-radio :label="2">竖版</el-radio>
				</el-radio-group>
			</div>
		</div>
		<div class="homeSet-box" v-if="open_goods == true">
			<div class="box-item">
				<div class="item-title">分类样式</div>
				<el-radio-group v-model="goods_cate_kind">
					<el-radio :label="1">按商品分类</el-radio>
					<el-radio :label="2">按到货日期</el-radio>
				</el-radio-group>
			</div>
		</div>
		<div class="homeSet-footer">
			<el-button type="warning" @click.stop="confirmFn">确 定</el-button>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				open_goods: true,
				goods_view_kind: 1,
				goods_cate_kind: 1,
			};
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList() {
				this.$http.post(`/erp/v1/home/get_homepage_set`).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						data.open_goods == 0 ? this.open_goods = false : this.open_goods = true
						this.goods_view_kind = data.goods_view_kind
						this.goods_cate_kind = data.goods_cate_kind
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 确定
			confirmFn() {
				this.$http.post(`/erp/v1/home/save_view_goods`, {
					goods_view_kind: this.goods_view_kind,
					goods_cate_kind: this.goods_cate_kind,
					open_goods: this.open_goods == true ? 1 : 0,
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.$message.success(msg);
						this.getList()
					} else {
						this.$message.error(msg);
					}
				});
			},
		},
	};
</script>
<style lang="scss" scoped>
	.homeSet {
		.homeSet-box {
			background: #fff;
			border-radius: 6px;
			padding: 20px;
			margin-bottom: 20px;

			.box-item {
				display: flex;
				align-items: center;

				.item-title {
					width: 100px;
					font-size: 14px;
				}

				.item-b {
					display: flex;
					flex-direction: column;
				}
			}
		}

		.homeSet-footer {
			display: flex;
			justify-content: center;

		}

	}
</style>